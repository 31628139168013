export const stringToObject = (value: string) => {
  if (!value) return {}

  const properties = value.split(', ')
  const obj: Record<string, any> = {}

  properties.forEach(function (property) {
    const tup = property.split(':')
    obj[tup[0]] = tup[1]
  })

  return obj
}

export const getSKUfromSize = (size: string) => {
  return (stringToObject(size)?.SKU || '').trim()
}

export const getFileTypeFromUrl = (url: string) => {
  const regex = /(?:\.([^.]+))?$/
  const extension = regex.exec(url)?.at(1)
  return extension?.toLowerCase() ?? ''
}
