import type {
  AlgoliaProductType,
  CLPriceInfo,
} from '@integration-layer/components/Algolia/AlgoliaAisInfiniteHits.props'
import type { InstantSearch, UiState } from 'instantsearch.js/es/types'

type Timestamp = number
export const useFetchedSkus = () =>
  useState<{ [sku: string]: Timestamp }>('plp_fetched_skus_record', () =>
    shallowRef({})
  )

const cacheTIme = 30 * 60 * 1000
const isValid = (timestamp: Timestamp | undefined) =>
  !!timestamp && Date.now() - timestamp < cacheTIme

export default function useProductTileClPrices() {
  const { $cl } = useNuxtApp()

  // A list of the SKUs already fetched in previous PLP pages
  const fetchedSkus = useFetchedSkus()
  /**
   * Reactivelytores the prices of the SKUs passed as arg to the fetchSkuPrices function
   */
  const skuPrices = useState<Record<string, CLPriceInfo>>('plp_skus', () =>
    shallowRef({})
  )

  /**
   * @title fetchSkuPrices
   *
   * Fetches the prices of SKUs and updates the `skuPrices` ref.
   *
   * @param skuListParam - An array of SKU codes.
   * @returns void
   */
  const fetchSkuPrices = async (skuListParam: string[]) => {
    if (!skuListParam.length) return
    const skusToFetch =
      skuListParam.filter(skuCode => !isValid(fetchedSkus.value[skuCode])) ?? []

    if (!skusToFetch.length) return

    const now = Date.now()
    const updatedFetchedSkus = { ...fetchedSkus.value }
    for (const sku of skusToFetch) {
      updatedFetchedSkus[sku] = now
    }
    fetchedSkus.value = updatedFetchedSkus

    const newSkuPrices = await Promise.all(
      chunk(skusToFetch, 10).map(skuListParam =>
        $cl.skus.list({
          include: ['prices'],
          filters: { code_in: skuListParam.join(',') },
          pageSize: 10,
        })
      )
    ).then(responses => responses.flat())

    const newPricesMappedObject = newSkuPrices.reduce(
      (skuPricesBySkuCode, sku) => {
        const price = sku.prices?.at(0)

        return {
          ...skuPricesBySkuCode,
          [sku.code]: {
            originalPrice: price?.compare_at_amount_float!,
            discountedPrice: price?.amount_float ?? '',
            discount: price?.amount_float
              ? percentageDiscount(
                  price?.compare_at_amount_float!,
                  price?.amount_float!
                )
              : '',
          },
        }
      },
      {}
    )

    skuPrices.value = {
      ...skuPrices.value,
      ...newPricesMappedObject,
    }
  }

  /**
   * Same as fetchSkuPrices but takes as argument an AlgoliaInstantSearchInstance and the Algolia index name, and assignes the new prices to the skuPrices ref.
   * Meant for usage inside an Algolia InstantSearch middleware
   */
  const fetchSkuPricesAlgoliaMiddleware = async (
    instantSearchInstance: InstantSearch<UiState, UiState>
  ) => {
    // wait for the shallow ref instance to be updated
    await nextTick()
    // get index name
    const index = instantSearchInstance.mainIndex.getIndexId()
    // access renderstate of infiniteHits of current index
    // as a fallback get latest results

    const hits = instantSearchInstance.renderState?.[index]?.infiniteHits?.hits
      .length
      ? instantSearchInstance.renderState?.[index]?.infiniteHits?.hits
      : instantSearchInstance.mainIndex.getResults()?.hits

    const hitsSkus =
      hits
        ?.map(item => item as unknown as AlgoliaProductType)
        ?.map(item => item?.size?.[0]?.SKU ?? null)
        ?.filter(code => isNonNullable(code)) ?? []

    await fetchSkuPrices(hitsSkus)
  }

  const resetAll = () => {
    fetchedSkus.value = {}
    skuPrices.value = {}
  }

  return {
    skuPrices,
    fetchSkuPrices,
    fetchSkuPricesAlgoliaMiddleware,
    resetAll,
  }
}
